<template>
  <div :class="dashMode ? 'dashboard-mode' : ''">
    <Brand v-if="dashMode" :hideControls="true" style="background: none" />
    <div class="row">
      <div class="col-xl-8 col-lg-8 order-lg-3 order-xl-1">
        <KTPortlet
          v-bind:class="'kt-portlet--height-fluid'"
          v-bind="{ bodyFit: true }"
        >
          <template v-slot:body>
            <div class="kt-widget14" style="height: 100%">
              <div class="kt-widget14__header">
                <h3 class="kt-widget14__title">Network Overview</h3>
              </div>
              <div class="kt-widget14__chart" style="height: 30vh">
                <Loader
                  v-if="networkStatusGraph === null"
                  :inContent="true"
                  text="Loading data"
                ></Loader>
                <div
                  v-else-if="networkStatusGraphLabels.length === 0"
                  style="width: 100%; text-align: center"
                >
                  No devices adopted yet.
                  <router-link
                    :to="`/Networks/${$route.params.serviceId}/Groups?action=setup`"
                    >adopt a new device</router-link
                  >
                </div>
                <Chart2
                  v-else
                  :labels="networkStatusGraphLabels"
                  :datasets="networkStatusGraphDataset"
                  :options="networkStatusGraph"
                ></Chart2>
              </div>
            </div>
          </template>
        </KTPortlet>
      </div>
      <div class="col-xl-4 col-lg-4 order-lg-3 order-xl-1">
        <KTPortlet
          v-bind:title="'Groups'"
          v-bind:class="'kt-portlet--tabs kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            <div class="kt-widget4">
              <Loader
                v-if="networkStatusGraph === null"
                :inContent="true"
                text="Loading data"
              ></Loader>
              <div
                v-else-if="networkStatusGraphLabels.length === 0"
                style="width: 100%; text-align: center"
              >
                No devices adopted yet.
                <router-link
                  :to="`/Networks/${$route.params.serviceId}/Groups?action=setup`"
                  >adopt a new device</router-link
                >
              </div>
              <div
                v-else-if="networkStatusGroups.length === 0"
                style="width: 100%; text-align: center"
              >
                No groups added yet.
                <router-link
                  :to="`/Networks/${$route.params.serviceId}/Groups?action=add`"
                  >add a new group</router-link
                >
              </div>
              <perfect-scrollbar
                v-else
                style="max-height: 100vh; position: relative"
              >
                <template v-for="(item, i) in networkStatusGroups">
                  <div class="kt-widget4__item" :key="`item-${i}`">
                    <!-- <div class="kt-widget4__pic kt-widget4__pic--pic">
                      <img :src="item.image" alt="" />
                    </div> -->
                    <div class="kt-widget4__info">
                      <router-link
                        :to="`/Networks/${$route.params.serviceId}/Devices?groupfilter=${item.id}`"
                        class="kt-widget4__username"
                        >{{ item.name }}</router-link
                      >
                      <p class="kt-widget4__text">
                        <span>offline: {{ item.offline }}</span> /
                        <span>recent: {{ item.offOnline }}</span> /
                        <span>online: {{ item.online }}</span>
                      </p>
                    </div>
                    <v-chip
                      v-if="item.percentageStatus === 100"
                      class="ma-2"
                      :style="`background: var(--success); color: #fff;font-weight: 700;`"
                      >{{ item.percentageStatus.toFixed(0) }}%</v-chip
                    >
                    <v-chip
                      v-else-if="item.offline === 0"
                      class="ma-2"
                      :style="`background: var(--warning); color: #fff;font-weight: 700;`"
                      >{{ item.percentageStatus.toFixed(0) }}%</v-chip
                    >
                    <v-chip
                      v-else
                      class="ma-2"
                      :style="`background: var(--danger); color: #fff;font-weight: 700;`"
                      >{{ item.percentageStatus.toFixed(0) }}%</v-chip
                    >
                    <!-- <a href="#" class="btn" v-bind:class="item.button.style">{{
                      item.button.text
                    }}</a> -->
                  </div>
                </template>
              </perfect-scrollbar>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
    <!-- <div class="row" v-if="false">
      <div class="col-xl-4 col-lg-4 order-lg-3 order-xl-1">
        <KTPortlet
          v-bind:class="'kt-portlet--height-fluid'"
          v-bind="{ bodyFit: true }"
        >
          <template v-slot:body>
          </template>
        </KTPortlet>
      </div>
      <div class="col-xl-4 col-lg-4 order-lg-3 order-xl-1">
        <KTPortlet
          v-bind:title="'Groups'"
          v-bind:class="'kt-portlet--tabs kt-portlet--height-fluid'"
        >
          <template v-slot:body>
            
          </template>
        </KTPortlet>
      </div> 
    </div> -->
    <div class="row quickfoot">
      <div class="col-10">
        <b-button @click="dashMode = !dashMode" size="sm" variant="text">
          {{ dashMode ? "hide" : "show" }} fullscreen dashboard
        </b-button>
      </div>
      <div class="col-2" style="text-align: right; padding-right: 20px">
        <b-button @click="updateData(true)" size="sm" variant="text">
          last updated:
          {{ uiLastUpdate }}s ago
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import Brand from "@/views/theme/brand/Brand.vue";
// import ContextMenu1 from "@/views/partials/widgets/ContextMenu1.vue";
//import Widget4 from "@/views/partials/widgets/Widget4.vue";
// import Widget5 from "@/views/partials/widgets/Widget5.vue";
// import Widget14_1 from "@/views/partials/widgets/Widget14_MiniDailySales.vue";
// import Widget14_2 from "@/views/partials/widgets/Widget14_MiniProfitShare.vue";
// import Widget14_3 from "@/views/partials/widgets/Widget14_MiniRevenueChange.vue";
// import Widget17 from "@/views/partials/widgets/Widget17.vue";
// import Widget20_1 from "@/views/partials/widgets/Widget20_Inbound.vue";
// import Widget20_2 from "@/views/partials/widgets/Widget20_Outbound.vue";
// import Timeline2 from "@/views/partials/widgets/Timeline2.vue";

/**
 * Sample widgets data source
 */
import timelines from "@/common/mock/widget-timeline.json";
import widget4 from "@/common/mock/widget-4.json";
import widget5 from "@/common/mock/widget-5.json";

import TLib from "../lib";
import { mapGetters } from "vuex";
import Chart2 from "@/views/partials/widgets/Chart2.vue";
import MOMENT from "moment";

export default {
  name: "dashboard",
  components: {
    KTPortlet,
    Loader,
    Brand,
    // ContextMenu1,
    //Widget4,
    // Widget5,
    // Widget14_1,
    // Widget14_2,
    // Widget14_3,
    // Widget17,
    // Widget20_1,
    // Widget20_2,
    // Timeline2,

    Chart2,
  },
  data() {
    return {
      dashMode: false,
      deadPeriodLimit: 60 * 4 + 59,
      deadLimit: 61, //(60 * 1) + 59,
      timelines: timelines,
      widget4: widget4,
      widget5: widget5,
      tabIndex: 0,
      tabIndex2: 0,

      devices: null,
      types: null,
      templates: null,
      gateways: null,
      groups: null,
      hasDevicesCache: false,

      networkStatusGroups: null,
      networkStatusGraph: null,
      networkStatusGraphLabels: [],
      networkStatusGraphDataset: [],

      uiLastUpdate: 0,
    };
  },
  beforeDestroy() {
    this.$eventBus.off(`switch-service-selected`);
    this.$eventBus.off("ws-networks-tr069-device-informed");
    this.$eventBus.off("ws-networks-tr069-device-added");
    this.$eventBus.off("ws-networks-tr069-device-adopted");
    this.$eventBus.off("ws-networks-device-added");
    //this.$eventBus.off("ws-networks-device-updated");
    this.$eventBus.off("ws-networks-device-removed");
    this.$eventBus.off("cron-minute");
    this.$eventBus.off("cron-second");
    this.$eventBus.off("cron-hour");
    this.$eventBus.off("ws-state");
    //this.$eventBus.off("ws-networks-device-updated-status");
  },
  mounted() {
    //console.log(this.$route);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Networks" },
      { title: "Dashbard" },
    ]);

    let self = this;
    self.updateData();
    this.$eventBus.on(`switch-service-selected`, () => self.updateData());
    const deviceUpdateTrigger = (data) => {
      let deviceFound = false;
      if (!self.$tools.isNullOrUndefined(self.devices)) {
        for (let i = 0; i < self.devices.length; i++) {
          if (self.devices[i].id === data.id) {
            for (let key of Object.keys(data)) {
              self.devices[i][key] = data[key];
            }
            deviceFound = true;
            break;
          }
        }
      }
      if (!deviceFound) {
        self.updateData(true);
        return;
      }
      self.$nextTick(() => {
        TLib.forceDevicesCacheUpdate(
          self,
          self.$route.params.serviceId,
          self.devices
        );
        self.updateNetworkStatusGraph();
      });
    };
    this.$eventBus.on("ws-networks-tr069-device-informed", deviceUpdateTrigger);
    this.$eventBus.on("ws-networks-tr069-device-added", deviceUpdateTrigger);
    this.$eventBus.on("ws-networks-tr069-device-adopted", deviceUpdateTrigger);
    this.$eventBus.on("ws-networks-device-added", deviceUpdateTrigger);
    //this.$eventBus.on("ws-networks-device-updated", deviceUpdateTrigger);
    this.$eventBus.on("ws-networks-device-removed", deviceUpdateTrigger);
    this.$eventBus.on("cron-minute", () => self.updateData());
    this.$eventBus.on("ws-state", (state) => {
      if (!state) return;
      self.updateData(true);
    });
    this.$eventBus.on("cron-second", () => {
      self.$data.uiLastUpdate++;
    });
    this.$eventBus.on("cron-hour", () => {
      self.updateData(true);
    });
    /*this.$eventBus.on("ws-networks-device-updated", () => {
      self.updateData(true);
    });*/

    //this.createNetworkStatusGraph();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    orderedGroups() {
      let grouped = this.groups;
      for (let i = 0; i < grouped.length - 1; i++) {
        for (let j = i + 1; j < grouped.length; j++) {
          if (grouped[i].id > grouped[j].id) {
            let tmp = grouped[j];
            grouped[j] = grouped[i];
            grouped[i] = tmp;
          }
        }
      }
      return grouped;
    },
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },

    updateData(bumpCache = false) {
      return new Promise((resolve) => {
        let self = this;
        if (!bumpCache) self.$data.devices = null;
        else {
          self.$data.hasDevicesCache = false;
        }
        TLib.getGroups(self, self.$route.params.serviceId, bumpCache)
          .then((data) => {
            if (bumpCache) {
              self.$data.groups = [];
              return self.$nextTick(() => {
                self.$data.groups = data;
                resolve();
              });
            }
            self.$data.groups = data;
            resolve();
          })
          .catch((x) => {
            self.$log.error(x);
            //console.error("Error loading content");
          });
        TLib.getTypes(self, self.$route.params.serviceId)
          .then((typex) => {
            self.$data.types = typex;
            TLib.getTemplates(self, self.$route.params.serviceId)
              .then((templatex) => {
                self.$data.templates = templatex;
                TLib.getGateways(self, self.$route.params.serviceId)
                  .then((gatewaysz) => {
                    self.$data.gateways = gatewaysz;
                    TLib.getDevices(
                      self,
                      self.$route.params.serviceId,
                      undefined,
                      bumpCache
                    )
                      .then((data) => {
                        if (bumpCache) {
                          self.$data.devices = [];
                          return self.$nextTick(() => {
                            self.$data.devices = data;
                            self.$nextTick(() => {
                              self.updateNetworkStatusGraph();
                              resolve();
                            });
                          });
                        }
                        self.$data.devices = data;
                        self.$nextTick(() => {
                          self.$data.devices = data;
                          self.$nextTick(() => {
                            self.updateNetworkStatusGraph();
                            resolve();
                          });
                        });
                        resolve();
                      })
                      .catch((x) => {
                        self.$log.error(x);
                        //console.error("Error loading content");
                      });
                  })
                  .catch((x) => {
                    self.$log.error(x);
                    //console.error("Error loading content");
                  });
              })
              .catch((x) => {
                self.$log.error(x);
                //console.error("Error loading content");
              });
          })
          .catch((x) => {
            self.$log.error(x);
            //console.error("Error loading content");
          });
      });
    },
    DIFF(x, st) {
      return MOMENT().diff(MOMENT(x), st);
    },
    updateNetworkStatusGraphData() {
      let groupsData = [];
      let arrOfLabels = [];
      let offlineDataset = {
        backgroundColor: this.layoutConfig("colors.state.danger"),
        label: "Offline",
        data: [],
      };
      let offOnDataset = {
        backgroundColor: this.layoutConfig("colors.state.warning"),
        label: "Seen recently",
        data: [],
      };
      let onlineDataset = {
        backgroundColor: this.layoutConfig("colors.state.success"),
        label: "Online",
        data: [],
      };
      let pendingAdoptDataset = {
        backgroundColor: this.layoutConfig("colors.state.info"),
        label: "Pending adoption",
        data: [],
      };
      let pendingUploadDataset = {
        backgroundColor: this.layoutConfig("colors.state.primary"),
        label: "Pending first upload",
        data: [],
      };
      for (let group of this.orderedGroups) {
        let groupStateCount = {
          count: 0,
          offline: 0,
          offOnline: 0,
          online: 0,
          pendingAdopt: 0,
          pendingUpload: 0,
          percentageStatus: 0,
        };
        for (let device of this.devices) {
          if (device.groupId !== group.id) continue;
          if (device.active !== true && device.authorized === true) continue;
          groupStateCount.count++;
          if (device.authorized === false) {
            groupStateCount.pendingAdopt++;
            continue;
          }
          if (`${device.lastInform}` === "0") {
            groupStateCount.pendingUpload++;
            continue;
          }
          let thisDiff = this.DIFF(
            Number.parseInt(`${device.lastInform}`),
            "seconds"
          );
          if (thisDiff < this.deadLimit) groupStateCount.online++;
          else if (thisDiff < this.deadPeriodLimit) groupStateCount.offOnline++;
          else groupStateCount.offline++;
        }
        if (groupStateCount.count > 0) {
          groupStateCount.percentageStatus =
            ((groupStateCount.count -
              (groupStateCount.offline + groupStateCount.offOnline)) /
              groupStateCount.count) *
            100;
          /*arrOfLabels.push(group.name);
          offlineDataset.data.push(groupStateCount.offline);
          offOnDataset.data.push(groupStateCount.offOnline);
          onlineDataset.data.push(groupStateCount.online);
          pendingAdoptDataset.data.push(groupStateCount.pendingAdopt);
          pendingUploadDataset.data.push(groupStateCount.pendingUpload);*/
          groupsData.push({
            ...group,
            ...groupStateCount,
          });
        }
      }
      for (let group of groupsData) {
        arrOfLabels.push(group.name);
        offlineDataset.data.push((group.offline / group.count) * 100);
        offOnDataset.data.push((group.offOnline / group.count) * 100);
        onlineDataset.data.push((group.online / group.count) * 100);
        pendingAdoptDataset.data.push((group.pendingAdopt / group.count) * 100);
        pendingUploadDataset.data.push(
          (group.pendingUpload / group.count) * 100
        );
      }
      let arrOfDatasets = [
        offlineDataset,
        offOnDataset,
        onlineDataset,
        pendingAdoptDataset,
        pendingUploadDataset,
      ];
      // convert to percent
      for (let x = 0; x < arrOfDatasets.length; x++) {
        arrOfDatasets[x].data;
      }
      if (
        !this.$tools.isNullOrUndefined(this.$data.networkStatusGraphLabels) &&
        this.$data.networkStatusGraphLabels.length != arrOfLabels.length
      )
        this.$data.networkStatusGraphLabels = arrOfLabels;
      this.$data.networkStatusGraphDataset = arrOfDatasets;
      for (let i = 0; i < groupsData.length - 1; i++) {
        for (let j = i + 1; j < groupsData.length; j++) {
          if (groupsData[i].percentageStatus > groupsData[j].percentageStatus) {
            let tmp = groupsData[i];
            groupsData[i] = groupsData[j];
            groupsData[j] = tmp;
          }
        }
      }
      this.$data.networkStatusGroups = groupsData;
      this.$data.uiLastUpdate = 0;
    },
    updateNetworkStatusGraph() {
      this.updateNetworkStatusGraphData();
      if (this.networkStatusGraph === null) {
        this.createNetworkStatusGraph();
      }
    },
    createNetworkStatusGraph() {
      let delayed;
      this.$data.networkStatusGraph = {
        type: "bar",
        options: {
          animation: {
            onComplete: () => {
              delayed = true;
            },
            delay: (context) => {
              let delay = 0;
              if (
                context.type === "data" &&
                context.mode === "default" &&
                !delayed
              ) {
                delay = context.dataIndex * 300 + context.datasetIndex * 100;
              }
              return delay;
            },
          },
          title: {
            display: false,
          },
          tooltips: {
            intersect: false,
            mode: "nearest",
            xPadding: 10,
            yPadding: 10,
            caretPadding: 10,
          },
          legend: {
            display: true,
            position: "bottom",
          },
          responsive: true,
          maintainAspectRatio: false,
          barRadius: 4,
          scales: {
            x: {
              display: true,
              gridLines: false,
              stacked: true,
            },
            y: {
              display: false,
              stacked: true,
              gridLines: false,
            },
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            },
          },
        },
      };
    },
  },
};
</script>

<style scoped>
.dashboard-mode {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 999;
  background: #333334;
}

.dashboard-mode > .row {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 0;
}

.dashboard-mode > .quickfoot {
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 999;
  padding: 10px;
  background: #333334;
}
</style>