<template>
  <canvas ref="chart"></canvas>
</template>

<script>
import { mapGetters } from "vuex";

import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

export default {
  name: "chart-2",
  components: {},
  props: {
    options: Object,
    labels: Array,
    datasets: Array,
    //updateMode: String
  },
  mounted() {
    setTimeout(() => this.initChart());
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  data() {
    return {
      myChart: null,
      updateMode: undefined,
    };
  },
  watch: {
    labels: {
      deep: true,
      handler() {
        if (this.myChart === null) return;
        this.$data.myChart.data.labels = this.labels;
        this.myChart.update(this.updateMode); //this.updateMode || (this.$data.myChart.data.labels.length === 0 ? 'show' : 'active'))
      },
    },
    datasets: {
      deep: true,
      handler() {
        if (this.myChart === null) return;
        let hasChanges = false;
        if (this.myChart.data.datasets.length != this.datasets.length) {
          this.$data.myChart.data.datasets = this.datasets;
          hasChanges = true;
        } else {
          for (let i = 0; i < this.datasets.length; i++) {
            if (
              this.datasets[i].data.length !=
              this.myChart.data.datasets[i].data.length
            ) {
              this.$data.myChart.data.datasets[i].data = this.datasets[i].data;
              hasChanges = true;
            } else {
              if (this.datasets[i].data != this.myChart.data.datasets[i].data) {
                this.$data.myChart.data.datasets[i].data = this.datasets[
                  i
                ].data;
                hasChanges = true;
              }
            }
          }
        }
        if (hasChanges) this.myChart.update(this.updateMode); //this.updateMode || (this.$data.myChart.data.datasets.length === 0 ? 'show' : 'active'))
      },
    },
  },
  methods: {
    /**
     * For more chart.js options, please refer to the documentation
     * https://www.chartjs.org/docs/latest/
     */
    initChart() {
      const ctx = this.$refs["chart"].getContext("2d");

      const defaults = {
        type: "line",
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          title: {
            display: false,
          },
          tooltips: {
            mode: "nearest",
            intersect: false,
            position: "nearest",
            xPadding: 10,
            yPadding: 10,
            caretPadding: 10,
          },
          legend: {
            display: false,
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              display: false,
              gridLines: false,
              scaleLabel: {
                display: true,
                labelString: "Month",
              },
            },
            y: {
              display: false,
              gridLines: false,
              scaleLabel: {
                display: true,
                labelString: "Value",
              },
              ticks: {
                beginAtZero: true,
              },
            },
          },
          elements: {
            line: {
              tension: 0.0000001,
            },
            point: {
              radius: 4,
              borderWidth: 12,
            },
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 10,
              bottom: 0,
            },
          },
        },
      };

      let config = Object.assign({}, defaults, this.options);
      config.data.labels = this.labels || [];
      config.data.datasets = this.datasets || [];

      //console.log(config);
      this.$data.myChart = new Chart(ctx, config);
    },
  },
};
</script>
